<template>
  <!-- Main content -->
  <div class="main-content">
    <!-- Header -->
    <div class="header bg-gradient-dark py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Login!</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Sign in with your credentials</small>
              </div>
              <form v-on:submit.prevent="submitForm">
                <div class="form-group mb-3">
                  <div class="input-group input-group-merge input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                    </div>
                    <input class="form-control" placeholder="Email" type="email" id="email" v-model="form.email">
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group input-group-merge input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                    </div>
                    <input class="form-control" placeholder="Password" type="password" id="password" v-model="form.password">
                  </div>
                </div>
                <div class="text-center">
                  <button type="submit" class="btn btn-primary my-4">Sign In</button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link to="/" class="text-light">Forgot Password</router-link>
            </div>
            <div class="col-6 text-right">
              <router-link to="/register" class="text-light">Register</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
export default {
    name: 'Signin',
    computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
    data() {
        return{
            form: {
                email: 'info@farm360.org',
                password: '',
            },
        }
    },
    mounted(){
        if (this.authToken) {
            this.$router.push('/');
        }
    },
    methods:{
        submitForm(){
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_AUTHURL+'/signin', this.form,
            {
                headers: {'Device-Referrer-Id': process.env.VUE_APP_DEVICEID}
            })
            .then((res) => {
                //Perform Success Action
                localStorage.mydata = JSON.stringify(res.data.data);
                this.$store.commit("authToken", res.data.token)
                this.$router.push('/');
            })
            .catch((error) => {
                // error.response.status Check status code
                this.$moshaToast(error.response.data.message,{
                    type: 'danger'
                });
            })
            .finally(() => {
                loader.hide()
            });
        }
    },
}
</script>

